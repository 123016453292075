<template>
	<div class="filter-container">
		<div class="label">
			{{ $t('filters.filter_by') }}
		</div>
		<div class="filter-items">
			<img class="filter-icon" src="@/../public/img/location_filter.svg" />
			<v-autocomplete v-if="dates" :items="Object.values(sites)" v-model="currentFilters.id_sites" :item-text="(item) => (item.code != 'all' ? `${item.code} - ${cleanString(item.name)}` : item.name)" item-value="id" class="select">
				<template slot="selection" slot-scope="data">
					<!-- HTML that describe how select should render selected items -->
					{{ data.item.code != 'all' ? `${data.item.code} - ${data.item.name}` : $t(data.item.name) }}
				</template>
				<template slot="item" slot-scope="data">
					<!-- HTML that describe how select should render items when the select is open -->
					{{ data.item.code != 'all' ? `${data.item.code} - ${data.item.name}` : $t(data.item.name) }}
				</template>
			</v-autocomplete>
		</div>
		<!-- <div class="filter-items">
			<img class="filter-icon" src="@/../public/img/user_icon.svg" />
			<v-autocomplete v-if="dates" :items="Object.values(customers)" v-model="currentFilters.customers" class="select">
				<template slot="selection" slot-scope="data">
					{{ $t(data.item) }}
				</template>
				<template slot="item" slot-scope="data">
					{{ $t(data.item) }}
				</template>
			</v-autocomplete>
		</div> -->
		<div class="filter-items">
			<img class="filter-icon" src="@/../public/img/calendar_filter.svg" />
			<v-select v-if="dates" :items="Object.values(dates)" v-model="currentFilters.date" item-text="name" item-value="code" class="select">
				<template slot="selection" slot-scope="data">
					<!-- HTML that describe how select should render selected items -->
					{{ $t(data.item.name) }}
				</template>
				<template slot="item" slot-scope="data">
					<!-- HTML that describe how select should render items when the select is open -->
					{{ $t(data.item.name) }}
				</template>
			</v-select>
			<div class="custom" v-if="currentFilters.date == 'custom'">
				<div class="description">{{ $t('filters.from') }}:</div>
				<input class="date-input" v-model="currentFilters.start_date" type="date" />
				<div class="description">{{ $t('filters.to') }}:</div>
				<input class="date-input" :min="currentFilters.start_date" v-model="currentFilters.end_date" type="date" />
			</div>
		</div>
		<div class="filter-actions">
			<div class="cancel" v-if="JSON.stringify(currentFilters) != JSON.stringify(emptyFilters)" @click="resetFilters()">{{ $t('filters.reset_filters') }}</div>
			<div class="filter" v-if="changesDetected" @click="applyFilters()">{{ $t('filters.filter') }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ValidTicketsFilter',
	props: {
		pagination: {
			type: Object,
			default: false
		},
		sortBy: { type: undefined, default: false },
		sortType: { type: undefined, default: false },
		changePagination: { type: undefined, default: false }
	},
	data() {
		return {
			dateSelected: false,
			currentFilters: {},
			emptyFilters: {
				date: 'month',
				id_sites: 'filters.all_restaurants'
			},
			activeFilters: {},
			changesDetected: false
		}
	},
	computed: {
		dates() {
			return this.$store.getters['validTickets/getValidTicketsFilters']?.date
		},
		sites() {
			return this.$store.getters['validTickets/getValidTicketsFilters'].id_sites
		}
		// customers() {
		// 	return this.$store.getters['bills/getBillsFilters'].customers
		// }
	},
	methods: {
		load() {
			this.applyFilters()
		},
		resetFilters() {
			var self = this
			this.$emit('resetPagination')
			this.$overlay.show()
			this.currentFilters = { ...this.emptyFilters }
			this.activeFilters = { ...this.emptyFilters }
			this.start_date = ''
			this.end_date = ''
			this.$store.commit('validTickets/setValidTicketsFiltersActive', this.currentFilters)
			this.$store.dispatch('validTickets/loadValidTickets', { date: 'month', id_sites: '', type: 1 }).then(function () {
				self.$emit('resetFilters')
				self.$overlay.hide()
			})
		},
		applyFilters() {
			var self = this
			const iApplyFilters = this.changesDetected ? 1 : 0
			let pagenum = this.pagination.page

			if (iApplyFilters) {
				this.$emit('resetPagination')
				pagenum = 1
			}
			this.$overlay.show()
			this.$store.commit('validTickets/setValidTicketsFiltersActive', this.currentFilters)
			var params = { ...this.currentFilters }
			if (params.id_sites == 'filters.all_restaurants') {
				params.id_sites = ''
			}
			params.type = 1
			params.number_page = this.pagination.page
			params.number_items = this.pagination.itemsPerPage
			params.sortBy = this.sortBy
			params.sortType = this.sortType
			params.pagenum = pagenum

			// this.$store.dispatch('restaurant/loadRestaurants').then(function () {
			self.$store.dispatch('validTickets/loadValidTickets', params).then(function () {
				self.changesDetected = false
				self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))
				self.$emit('applyFilters')
				self.$overlay.hide()
			})
			// })
		},
		cleanString(string) {
			// var r=string.toLowerCase();
			// r = r.replace(new RegExp(/\s/g),"");
			var r = string
			r = r.replace(new RegExp(/[àáâãäå]/g), 'a')
			r = r.replace(new RegExp(/[ÀÁ]/g), 'A')
			r = r.replace(new RegExp(/[èéêë]/g), 'e')
			r = r.replace(new RegExp(/[ÈÉ]/g), 'E')
			r = r.replace(new RegExp(/[ìíîï]/g), 'i')
			r = r.replace(new RegExp(/[ÌÍ]/g), 'I')
			r = r.replace(new RegExp(/ñ/g), 'n')
			r = r.replace(new RegExp(/[òóôõö]/g), 'o')
			r = r.replace(new RegExp(/[ÒÓ]/g), 'O')
			r = r.replace(new RegExp(/[ùúûü]/g), 'u')
			r = r.replace(new RegExp(/[ÙÚ]/g), 'U')
			r = r.replace(new RegExp(/[ñ]/g), 'n')
			r = r.replace(new RegExp(/[Ñ]/g), 'N')
			return r
		},
		handleChangePagination() {
			var self = this
			const iApplyFilters = this.changesDetected ? 1 : 0
			let pagenum = this.pagination.page

			if (iApplyFilters) {
				this.$emit('resetPagination')
				pagenum = 1
			}
			this.$overlay.show()
			this.$store.commit('validTickets/setValidTicketsFiltersActive', this.currentFilters)
			var params = { ...this.currentFilters }
			if (params.id_sites == 'filters.all_restaurants') {
				params.id_sites = ''
			}
			params.type = 1
			params.number_page = this.pagination.page
			params.number_items = this.pagination.itemsPerPage
			params.sortBy = this.sortBy
			params.sortType = this.sortType
			params.pagenum = pagenum

			// this.$store.dispatch('restaurant/loadRestaurants').then(function () {
			self.$store.dispatch('validTickets/loadValidTickets', params).then(function () {
				self.changesDetected = false
				self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))
				self.$emit('applyFilters')
				self.$overlay.hide()
			})
		}
	},
	created() {},
	mounted() {
		this.activeFilters = { ...this.$store.getters['validTickets/getValidTicketsFiltersActive'] }
		this.currentFilters = { ...this.$store.getters['validTickets/getValidTicketsFiltersActive'] }
		this.load()
	},
	watch: {
		currentFilters: {
			handler: function () {
				if (JSON.stringify(this.currentFilters) != JSON.stringify(this.activeFilters)) {
					this.changesDetected = true
				} else {
					this.changesDetected = false
				}
			},
			deep: true
		},
		// pagination change apply filters
		pagination: {
			handler: function (newVal, oldVal) {
				if (this.changePagination) {
					this.handleChangePagination()
				}
			},
			deep: true
		}
	}
}
</script>

<style lang="scss"></style>
